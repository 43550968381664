@import url("https://fonts.googleapis.com/css2?family=Roboto");

.Dashboard {
    margin-left: 2em;
}

.Dashboard-children {
    display: flex;
    flex-direction: row;
}

.Device-list {
    list-style-type: none;
    border: solid black;
    max-width: 20em;
    height: 25em;
    padding-left: 0;
    margin-left: 2em;
    margin-right: 10em;
    overflow: auto;
}

.Device-list-item {
    border: none;
    border-bottom: solid black 1px;
    padding: 0.5em;
    font-size: 20px;
    font-family: "Roboto";
    width: 100%;
    max-width: 100%;
    text-align: left;
    background: white;
    cursor: pointer;
}

.Device-list-item:hover {
    background-color: #E8E8E8;
}


.Device-list-item-uid {
    font-size: 14px;
    color: grey;
    padding-left:2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ErrorText {
    color: red;
}

.value_input {
    width: 5em;
}

.value_input_wide {
    width: 10em;
}

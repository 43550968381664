.NameDisplayContainer {
    margin-bottom: 2em;
}

.NameDisplay {
    font-size: 35px;
    margin: 0.5em 0 0.2em 0;
    height: 1em;
    display: flex;
    align-items: center;
}

.NameDisplayText {
    font-size: inherit;
    line-height: 100%;
}

.ContactInfo {
    display: flex;
    margin: 0;
    margin-bottom: 2em;
    height: auto;
}

.ContactInfo p {
    margin: 0;
    margin-right: 2em;
}

.EditOptionsContainer {
    margin-bottom: 1em;
}

.DeviceSettings_Container {
    margin-left: 2em;
}

.UpdateContainer {
    display: flex;
    align-items: center;
}

.UpdateImage {
    width: 15px;
    height: 15px;
    margin-right: 10px
}

.Tab1 {
    margin-left: 2em;
}

.TabTitle {
    margin-bottom: 0 !important;
    font-weight: bold;
}

.RowTab {
    margin-left: 2em;
    padding-top: 0.5em;
    display: flex;
    margin-bottom: 0.5em;
}

.RowTab p {
    margin-right: 2em;
}

.ChemicalContainer {
    border: solid 1px;
    border-radius: 18px;
    margin-bottom: 1.5em;
}

.ChemicalContainer_Contents {
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 1em;
}

.ChemicalContainer_Contents p {
    margin-left: 1em;
}

.TitleText {
    font-size: 30px;
    color: gray;
}

.ChemicalContainer_Title {
    margin: 10px;
}

.ChemicalContainer_Contents .SettingsValueContainer {
    margin: 0 2em 1em 0;
}

.TextContainer {
    display: flex;
    align-items: center;
    height: 100%;
}

.TextDisplay {
    height: 100%;
    line-height: 100%;
    margin: 0 0.5em 0 0;
    font-size: inherit;
}

.EditModeButton {
    max-height: 100%;
    height: 3em;
}

.graphContainer {
    height: 300px;
    width: 800px;
    margin-bottom:4em;
}

.viewOptions {
    display: flex;
}

.viewOptions input {
    margin-right: 2em;
}
